/** @jsx jsx */
import { jsx } from "theme-ui"

import { Container } from "../components/Grid"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { H4, H3, P2 } from "../components/Typography"
import Link from "../components/Link"

// import Navbar from "../components/Navbar"

const LinkCustom = ({ ...props }) => <Link sx={{ color: "teal" }} {...props} />

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Accessibility statement" />
      <Container>
        <H3 as="h1">
          <strong>Accessibility Statement</strong>
        </H3>
        <P2>
          Web Accessibility is at the forefront of our priorities at Vretta. In
          line with the principles of the Web Content Accessibility Guidelines (
          <LinkCustom
            target="_blank"
            href="https://www.w3.org/WAI/standards-guidelines/wcag/"
          >
            WCAG
          </LinkCustom>
          ), we strive to create content that is Operable, Understandable,
          Perceivable, and Robust. Our accessibility standards are regularly
          reviewed and updated to adhere to accessibility guidelines and legal
          requirements. As we are based in Ontario, we are currently regulated
          by the Accessibility for Ontarians with Disabilities Act 2005 (
          <LinkCustom target="_blank" href="https://www.aoda.ca/the-act/">
            AODA
          </LinkCustom>
          ).
        </P2>
        <P2>The content under current review includes our:</P2>
        <ul>
          <li>Front-Page Website Content,</li>
          <li>Lessons and Labs,&nbsp;</li>
          <li>eTextbook,&nbsp;</li>
          <li>Solution Manuals,&nbsp;</li>
          <li>PowerPoint Presentations,&nbsp;</li>
          <li>Financial Calculator Resources,&nbsp;</li>
          <li>Formula Sheets,&nbsp;</li>
          <li>Glossary,&nbsp;</li>
          <li>Errata Sheet,&nbsp;</li>
          <li>Account Settings Tab,&nbsp;</li>
          <li>Help Desk / FAQ.&nbsp;</li>
        </ul>
        <P2>
          We are currently moving forward to comply with WCAG 2.0 Level AA by
          January 2020, meaning we will be AODA-compliant ahead of the legally
          required date by one year. Additionally, we aim to be WCAG 2.1 Level
          AA compliant early in 2020.&nbsp;
        </P2>
        <P2>
          We want as many people as possible to be able to use this website. For
          example, that means you should be able to:
        </P2>
        <ul>
          <li>change colours, contrast levels and fonts</li>
          <li>zoom in up to 300% without the text spilling off the screen</li>
          <li>navigate most of the website using just a keyboard</li>
          <li>
            navigate most of the website using speech recognition software
          </li>
          <li>
            listen to most of the website using a screen reader (including the
            most recent versions of JAWS, NVDA and VoiceOver)
          </li>
        </ul>
        <P2>
          We have also made the website text as simple as possible to
          understand.
        </P2>
        <P2>
          <LinkCustom target="_blank" href="https://mcmw.abilitynet.org.uk/">
            AbilityNet
          </LinkCustom>{" "}
          has advice on making your device easier to use if you have a specific
          disability.
        </P2>
        <H4>
          <strong>Accessibility of this website</strong>
        </H4>
        <P2>
          We are aware that the following on the website are not fully
          accessible:
        </P2>
        <ul>
          <li>
            some elements of the website do not function with tab and keyboard
            alone and some responses require specific mouse movements
          </li>
          <li>you cannot modify the line height or spacing of text</li>
          <li>
            some of our online forms are difficult to navigate using just a
            keyboard
          </li>
          <li>
            you cannot skip to the main content when using a screen reader
          </li>
        </ul>
        <P2>
          All of these issues will be made accessible by January 31st, 2020.
        </P2>
        <H4>
          <strong>What to do if you cannot access parts of this website</strong>
        </H4>
        <P2>
          If you need information on this website in a different format like
          accessible PDF, large print, easy read, audio recording or braille:
        </P2>
        <ul>
          <li>email Vretta Help Desk &lt;support@vretta.com&gt;</li>
          <li>call (866) 522-9228</li>
        </ul>
        <P2>We’ll consider your request and get back to you in 14 days.</P2>
        <H4>
          <strong>Reporting accessibility problems with this website</strong>
        </H4>
        <P2>
          We’re always looking to improve the accessibility of this website. If
          you find any problems not listed on this page or think we’re not
          meeting accessibility requirements, contact:&nbsp;
        </P2>
        <ul>
          <li>email Vretta Help Desk &lt;support@vretta.com&gt;</li>
          <li>call (866) 522-9228</li>
        </ul>
        <H4>
          <strong>Enforcement procedure</strong>
        </H4>
        <P2>
          The Equality and Human Rights Commission (EHRC) is responsible for
          enforcing the Public Sector Bodies (Websites and Mobile Applications)
          (No. 2) Accessibility Regulations 2018 (the ‘accessibility
          regulations’). If you’re not happy with how we respond to your
          complaint,&nbsp;
          <LinkCustom
            target="_blank"
            href="https://www.equalityadvisoryservice.com/"
          >
            contact the Equality Advisory and Support Service (EASS)
          </LinkCustom>
          .
        </P2>
        <H4>
          <strong>
            Technical information about this website’s accessibility
          </strong>
        </H4>
        <P2>
          Vretta is committed to making its website accessible, in accordance
          with the Public Sector Bodies (Websites and Mobile Applications) (No.
          2) Accessibility Regulations 2018.
        </P2>
        <P2>
          This website is not compliant with the&nbsp;
          <LinkCustom target="_blank" href="https://www.w3.org/TR/WCAG21/">
            Web Content Accessibility Guidelines version 2.1
          </LinkCustom>
          &nbsp;AA standard. The non-accessible sections are listed below.
        </P2>
        <H4>
          <strong>Non accessible content</strong>
        </H4>
        <P2>
          The content listed below is non-accessible for the following reasons.
        </P2>
        <H4>
          <strong>Non compliance with the accessibility regulations</strong>
        </H4>
        <P2>
          {`We're working hard to achieve our goal of Level AA accessibility, but we realise there are some areas that still need improving. The following information explains what we're doing to make that happen.`}
        </P2>
        <P2>1.1.1 (non-text content)</P2>
        <P2>
          Some images do not have a text alternative, so people using a screen
          reader cannot access the information. When we publish new content
          we’ll make sure our use of images meets accessibility standards.
        </P2>
        <P2>1.3.3 (Sensory Characteristics)</P2>
        <P2>
          Some elements (forward and backward buttons) rely solely on
          non-textual instructions for their operation.
        </P2>
        <P2>1.4.3 (Contrast minimum)</P2>
        <P2>
          The main website contains a section where the color contrast ratio is
          not great enough.&nbsp;
        </P2>
        <P2>1.4.5 (Images of Text)</P2>
        <P2>
          Some images of text in the frontpage website could otherwise be
          conveyed purely in text.
        </P2>
        <P2>2.1.1 (Keyboard Accessible)</P2>
        <P2>
          Some page elements are not accessible with tab or keyboard only.
        </P2>
        <P2>2.4.7 (Focus Visible)</P2>
        <P2>
          Some page elements do not visually identify the tab focus to the
          user.&nbsp;
        </P2>
        <P2>3.3.1 (Error Identification)</P2>
        <P2>
          Some input errors do not identify the specific error to the user in
          text.&nbsp;
        </P2>
        <P2>3.3.2 (Labels or Instructions)</P2>
        <P2>Not all user input elements have labels or instructions.</P2>
        <P2>All of these issues will be fixed by January of 2020.</P2>
        <H4>
          <strong>Disproportionate burden</strong>
        </H4>
        <P2>
          With regards to standards of accessibility that are legally required,
          we plan to have fixes in place well ahead of time. We do not believe
          any of the legally required accessibility standards place a
          disproportionate burden. Standards above this requirement (WCAG 2.1
          level AAA) are seen as placing a significant burden that would require
          significant changes to the site and applications.
        </P2>
        <H4>
          <strong>
            Content that’s not within the scope of the accessibility regulations
          </strong>
        </H4>
        <H4>
          <em>Animation from Interactions</em>
        </H4>
        <P2>
          Some of our items in the Pre-Assessments, Upgrade your Skills, and
          Post-Assessments contain motion animation that cannot be disabled.
          This does not meet WCAG 2.1 success criterion 2.3.4 (Animation from
          Interactions).
        </P2>
        <H4>
          <strong>How we tested this website</strong>
        </H4>
        <P2>
          This website was last tested on 2019-09-19. The test was carried out
          by Vretta in-house.
        </P2>
        <P2>
          We used this approach to deciding on a sample of pages to test based
          on what a general user may commonly experience.
        </P2>
        <P2>We tested:</P2>
        <P2>
          our main website platform, available at
          <LinkCustom target="_blank" href="http://elevatemymaths.com/">
            http://elevatemymaths.com/
          </LinkCustom>
        </P2>
        <P2>
          our Assessments and Upgrading, available for users at{" "}
          <LinkCustom
            target="_blank"
            href="https://secure.vretta.com/collegeLinkCustom/app/#/course-resource"
          >
            https://secure.vretta.com/collegeLinkCustom/app/#/course-resource
          </LinkCustom>
        </P2>
        <H4>
          <strong>What we’re doing to improve accessibility</strong>
        </H4>
        <P2>
          We’re working on meeting WCAG 2.1 Level AA by fixing all the
          accessibility problems indicated in this statement.
        </P2>
        <P2>
          This statement was prepared on 19th September 2019. It was last
          updated on 19th September 2019.
        </P2>
      </Container>
    </Layout>
  )
}

export default IndexPage
